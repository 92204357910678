.aboutUs {
  display: flex;
  flex-direction: column;
}

.aboutUs h2 {
  align-self: center;
}

.aboutUs-leaders {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}
