.navbar {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  color: var(--color-text);
  justify-content: space-between;
  height: 80px;
  z-index: 5;
}

.navbar.sticky {
  position: fixed;
  background-color: var(--color-bgLight);

  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  box-shadow: 2px 7px 6px 0px rgba(55, 56, 60, 0.28);
  -webkit-box-shadow: 2px 7px 6px 0px rgba(55, 56, 60, 0.28);
  -moz-box-shadow: 2px 7px 6px 0px rgba(55, 56, 60, 0.28);
}

.navbar-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
}
.navbar-logo-img {
  width: 55px;
  height: 55px;
}

.navbar-logo-img img {
  width: 100%;
  height: 100%;
}

.navbar-logo a {
  font-size: 1rem;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-grow: 2;
}

.navbar-links a {
  font-size: 0.9rem;
}

.navbar-links a:hover,
.navbar-menu-container a:hover {
  transform: scale(1.02);
  color: var(--color-bgRed);
}

.navbar-menu {
  display: none;
}

.navbar-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5rem;

  padding-bottom: 30px;

  background-color: var(--color-bgLight);
  position: absolute;
  top: 80px; /* adjust according to your navbar height */
  right: 0px;
  width: 100%;
  /* height: 200px; */
  border-top: 2px solid;
  border-top-color: var(--color-bgRed);

  transition: transform 1s ease 1s;
}

.navbar-menu-container a {
  margin-top: 10px;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: block;
  }
}
