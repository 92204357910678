.profileCard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer-div,
.inner-div {
  height: 410px;
  max-width: 300px;
  margin: 0 auto;
  position: relative;
}

.outer-div {
  perspective: 900px;
  perspective-origin: 50% calc(50% - 18em);
}

.inner-div {
  margin: 0 auto;
  border-radius: 5px;
  font-weight: 400;
  color: #071011;
  font-size: 1rem;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.8, -0.4, 0.2, 1.7);
  transform-style: preserve-3d;
}

.inner-div:hover .social-icon {
  opacity: 1;
  top: 0;
}

.outer-div:hover .inner-div {
  transform: rotateY(180deg);
}

.front,
.back {
  position: relative;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.front {
  cursor: pointer;
  height: 100%;
  background: var(--color-bgLight);
  border-radius: 5px;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.front__bkg-photo {
  position: relative;
  height: 150px;
  width: 280px;

  background-size: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.front__bkg-photo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.front__face-photo {
  position: relative;
  top: -60px;
  height: 150px;
  width: 150px;
  margin: 0 auto;
  border-radius: 50%;
  border: 5px solid var(--color-bgLightRed);

  background-size: cover;
}

.front__text {
  position: relative;
  top: -55px;
  margin: 0 auto;

  font-size: 18px;
}

.front__text-header {
  font-weight: 700;

  text-transform: uppercase;
  font-size: 20px;
}

.front__text-para {
  position: relative;
  top: 3px;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
}

.front-icons {
  position: relative;
  top: 0;
  font-size: 14px;
  margin-right: 6px;
  color: gray;
}

.front__text-hover {
  position: relative;
  top: 10px;
  font-size: 10px;
  color: var(--color-bgRed);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border: 2px solid var(--color-bgRed);
  padding: 8px 15px;
  border-radius: 30px;
  background: var(--color-bgRed);
  color: #fff;
}

.back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #071011;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-media-wrapper {
  font-size: 36px;
}

.social-icon {
  position: relative;
  top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0;
  color: #fff;
  transition: all 0.4s cubic-bezier(0.3, 0.7, 0.1, 1.9);
}

.social-icon:nth-child(1) {
  transition-delay: 0.6s;
}

.social-icon:nth-child(2) {
  transition-delay: 0.7s;
}

.social-icon:nth-child(3) {
  transition-delay: 0.8s;
}

.social-icon:nth-child(4) {
  transition-delay: 0.9s;
}

.fab {
  position: relative;
  top: 0;
  left: 0;
  transition: all 200ms ease-in-out;
}

.fab:hover {
  top: -5px;
}
