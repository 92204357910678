.gallery-container {
  background: var(--gradient-bgDark);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.gallery-container h2 {
  z-index: 1;
}
.gallery {
  padding-top: 5px;
}
.hello {
  opacity: 1 !important;
}

.full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.full .content {
  background-color: rgba(0, 0, 0, 0.75) !important;
  height: 100%;
  width: 100%;
  display: grid;
}

.full .content img {
  left: 50%;
  transform: translate3d(0, 0, 0);
  animation: zoomin 1s ease;
  max-width: 95%;
  max-height: 95%;
  margin: auto;
  align-self: center;
}

.byebye {
  opacity: 0;
}

.byebye:hover {
  transform: scale(0.2) !important;
}

.gallery {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}
