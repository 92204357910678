.imageView {
  width: 300px;
  height: 100%;

  margin: 10px;
  padding-bottom: 20px;

  /* color: var(--color-bgLight); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  /* background: rgba(238, 238, 238, 0.9); */
  /* background: rgb(58, 59, 63, 0.7); */
  background: var(--color-bgLight);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transition: transform 0.5s ease-in-out;
}

.imageView:hover {
  transform: scale(1.02);
}

.imageView-container {
  width: 290px;
  height: 250px;
}

.imageView-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.imageView p {
  font-size: 12px;
}

@media screen and (max-width: 425px) {
  .imageView {
    width: 250px;
  }

  .imageView-container {
    width: 250px;
    height: 250px;
  }
}
