.publicationCard {
  width: 200px;
  height: 200px;
  padding: 20px;
  margin: 10px;

  color: var(--color-bgLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  /* background: rgba(238, 238, 238, 0.9); */
  background: rgb(58, 59, 63, 0.8);

  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transition: transform 0.5s ease-in-out;
}
.publicationCard:hover {
  transform: scale(1.02);
}

.publicationCard h4 {
  text-align: center;
}
