.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-text);
}

.partners-logo {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.partner-logo-container {
  margin-top: 20px;
  width: 100px;
  height: 100px;
}

.partner-logo-container img {
  width: 100%;
  height: 100%;
}

.partner-logo-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.partner-logo-info p {
  color: var(--color-bgRed);
  font-weight: 300;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .partners-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
