.clubs {
  display: flex;
  flex-direction: column;
}

.clubs h2 {
  align-self: center;
}

.clubs h5 {
  margin-left: 20px;
}
