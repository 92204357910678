.whoAreWe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whoAreWe-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.whoAreWe-text p {
  font-weight: 300;
}

.whoAreWe-cards {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .whoAreWe-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .whoAreWe-text h1 {
    font-size: 16px;
  }
}
