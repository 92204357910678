.blogContainer {
  background-image: linear-gradient(
      to bottom,
      rgb(128, 0, 0, 0.9),
      rgb(152, 43, 28, 0.6)
    ),
    url(../../assets/blog.jpg);

  min-height: 30vh;

  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--color-bgLight);

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blogContainer-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
