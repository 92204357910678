.scCards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  gap: 10px;
}

.scCards-title {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
}
.SCommittee {
  padding-bottom: 30px;
}

/* styling for the individual standing committee page */
.specificCommittee {
  display: flex;
  flex-direction: column;
}

.specificCommittee h2 {
  align-self: center;

  margin-top: 1rem;
  margin-bottom: 1rem;
}

.image-container,
.leaders-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}
