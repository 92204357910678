.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacts-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}
.contacts-details-container div {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.contacts-details-container div:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 425px) {
  .contacts-details-container div a,
  .contacts-details-container div p {
    font-size: 13px;
  }
}
