@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --font-family: "Roboto Condensed", sans-serif;

  --gradient-bgDark: radial-gradient(
    circle,
    rgba(58, 59, 63, 1) 32%,
    rgba(39, 40, 46, 1) 55%
  );
  --gradient-text: linear-gradient(89.97deg, #e9260c 1.84%, #f49867 102.67%);
  --gradient-textLight: linear-gradient(
    89.97deg,
    #e8bb28 1.84%,
    #757a0c 102.67%
  );
  --gradient-textWhite: linear-gradient(45deg, #cebcba 1.54%, #dad4b5 102.67%);
  --color-bgRed: #800000;
  --color-bgLight: #f2e8c6;
  --color-bgDark: #3a3b3f;
  --color-bgLightDark: #37383c;

  --color-text: #dad4b5;
  --color-bgLightRed: #982b1c;

  /* --color-score: linear-gradient(
    to bottom,
    rgb(128, 0, 0, 0.9),
    rgb(152, 43, 28, 0.6)
  ); ORIGINAL */

  --color-scoph: linear-gradient(
    to bottom,
    rgb(250, 124, 61, 1),
    rgb(152, 43, 28, 0.4)
  );

  --color-scope: linear-gradient(
    to bottom,
    rgb(1, 129, 193, 1),
    rgb(152, 43, 28, 0.4)
  );

  --color-scorp: linear-gradient(
    to bottom,
    rgb(95, 144, 63, 1),
    rgb(152, 43, 28, 0.4)
  );

  --color-scomh: linear-gradient(
    to bottom,
    rgb(255, 214, 0, 1),
    rgb(152, 43, 28, 0.4)
  );

  --color-score: linear-gradient(
    to bottom,
    rgb(46, 74, 157, 1),
    rgb(152, 43, 28, 0.4)
  );

  --color-scome: linear-gradient(
    to bottom,
    rgb(0, 0, 0, 1),
    rgb(152, 43, 28, 0.4)
  );

  --color-scora: linear-gradient(
    to bottom,
    rgb(189, 32, 43, 1),
    rgb(152, 43, 28, 0.4)
  );

  /* color: #fa7c3d;
  color: #0181c1;
  color: #5f903f;
  color: #ffd600;
  color: #2e4a9d;
  color: #000000;
  color: #bd202b; */

  /* color: rgb(250, 124, 61);
  color: rgb(1, 129, 193);
  color: rgb(95, 144, 63);
  color: rgb(255, 214, 0);
  color: rgb(46, 74, 157);
  color: rgb(0, 0, 0);
  color: rgb(189, 32, 43); */
}
