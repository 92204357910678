.scard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  width: 300px;
  height: 100%;

  margin: 10px;

  color: var(--color-bgLight);
  /* background: rgb(152, 43, 28, 0.9); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transition: transform 0.5s ease-in-out;
}

.scard:hover {
  transform: scale(1.02);
}
.scard a {
  text-align: center;
}
.scard-logo {
  width: 150px;
  height: 150px;
  align-self: center;
}
.scard-logo img {
  width: 100%;
  height: 100%;
}

.scard p {
  font-size: 13px;
}
