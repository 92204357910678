.header {
  min-height: 80vh;

  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--color-bgLight);

  /* object-fit: cover; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 500px;
  gap: 1rem;
}

.header-text p {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .header-text p {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 425px) {
  .header-text {
    max-width: 300px;
  }
}
