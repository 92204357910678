.events {
  display: flex;
  flex-direction: column;
}

.events h2 {
  align-self: center;
}

.events button {
  margin-left: 1.2rem;
}
