.accordion-card {
  height: 100%;
  padding: 20px;
  margin: 10px;

  color: var(--color-bgRed);
  display: flex;
  flex-direction: column;

  gap: 10px;
  background: rgba(238, 238, 238, 0.9);
  /* background: rgb(58, 59, 63, 0.7); */
  /* background: rgb(152, 43, 28, 0.9); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transition: transform 0.5s ease-in-out;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  gap: 10px;
}

.accordion-content {
  padding: 5px;
  color: black;
}

@media screen and (max-width: 425px) {
  .accordion-content {
    font-size: 13px;
  }
}
