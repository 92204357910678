.hero {
  /* min-height: 70vh; */
  height: 100%;

  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: var(--color-bgLight);

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  gap: 1rem;
}

.hero-text p,
.hero-text h1 {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .hero-text p {
    font-size: 0.8rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: 425px) {
  .hero-text {
    max-width: 300px;
  }
}
