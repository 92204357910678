.eventsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: var(--color-bgDark);
}

.eventsContainer-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.eventsContainer-cards-upcoming,
.eventsContainer-cards-pastEvents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventsContainer-cards-upcoming h3,
.eventsContainer-cards-pastEvents h3 {
  color: var(--color-text);
}

.eventsContainer-upcoming-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.pastEvents-card {
  width: 300px;
  height: 100%;
  padding: 20px;
  margin: 10px;

  color: var(--color-bgRed);
  display: flex;
  flex-direction: column;

  gap: 10px;
  background: rgba(238, 238, 238, 0.9);
  /* background: rgb(58, 59, 63, 0.7); */
  /* background: rgb(152, 43, 28, 0.9); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transition: transform 0.5s ease-in-out;
}
.pastEvents-card:hover {
  transform: scale(1.02);
}

.pastEvents-card p {
  padding-top: 5px;
  border-top: 1px solid;
  border-top-color: var(--color-bgDark);
}
