* {
  font-family: var(--font-family);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
/* .bodyBg {
  background-color: var(--color-text);
} */
body {
  background-image: url("./assets/gbodyBg.jpg");
  /* background-image: url("./assets/bodyBg.jpg"); */
}
.bodyBg {
  background: rgba(255, 255, 255, 0.94);

  backdrop-filter: blur(16.1px);
  -webkit-backdrop-filter: blur(16.1px);

  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center;
}
.section-padding {
  padding: 1.2rem;
}

.redButton {
  background-color: var(--color-bgRed);
  color: var(--color-bgLight);
  padding: 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.redButton:hover {
  transform: scale(1.05);
}

.above-navbar {
  z-index: 6;
}

.paddingTop {
  padding-top: 90px;
}

.gradient-text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-textLight {
  background: var(--gradient-textLight);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-textWhite {
  background: var(--gradient-textWhite);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.radialDarkBg {
  background: var(--gradient-bgDark);
}

.underline {
  position: relative;
  margin-bottom: 10px;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust this value as needed */
  left: 0;
  width: 90%;
  height: 2px; /* Thickness of the border */

  background-color: var(--color-bgRed);
  z-index: 1;
}

.verticalLine {
  border-left: 2px solid var(--color-bgRed);
  padding-left: 5px;
}

.whiteUnderline {
  position: relative;
  margin-bottom: 10px;
}

.whiteUnderline::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust this value as needed */
  left: 0;
  width: 90%;
  height: 2px; /* Thickness of the border */
  background-color: var(--color-text);
}

@media screen and (max-widht: 768px) {
  .redButton {
    font-size: 0.8rem;
  }

  h1 {
    font-size: 16px;
  }
  p {
    font-size: 13px;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
