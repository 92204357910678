.publication {
  background-color: var(--color-bgRed);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.publication-text h2 {
  color: var(--color-text);
}

.publicationUnderline {
  position: relative;
  margin-bottom: 10px;
}

.publicationUnderline::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust this value as needed */
  left: 0;
  width: 90%;
  height: 3px; /* Thickness of the border */
  background-color: var(--color-text);
}

.publication-cards {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .publication-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}
