/* .textArea {
  background-color: var(--color-text);
} */

.textArea p {
  margin-top: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 425px) {
  .textArea p {
    font-size: 1rem;
  }
}
