.weCard {
  width: 250px;
  height: 250px;
  padding: 20px;
  margin: 10px;

  color: var(--color-bgLight);
  display: flex;
  flex-direction: column;
  align-items: center;

  /* background: rgba(238, 238, 238, 0.9); */
  /* background: rgb(58, 59, 63, 0.7); */
  background: rgb(152, 43, 28, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  transition: transform 0.5s ease-in-out;
}

.weCard:hover {
  transform: scale(1.02);
}

.weCard-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.weCard-text p {
  font-weight: 400;
}
