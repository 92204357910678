.hustleCorner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hustleCorner h2 {
  align-self: center;
}

.hustleCorner button {
  margin-left: 1.2rem;
}
