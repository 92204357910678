.footer {
  background-color: var(--color-bgRed);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.footer-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-links-logo {
  width: 100px;
  height: 100px;
}

.footer-links-logo img {
  width: 100%;
  height: 100%;
}

.footer-links-quick,
.footer-links-important {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer-links-quick h4,
.footer-links-important h4 {
  color: var(--color-text);
}
.footer-links-quick p,
.footer-links-important p {
  color: var(--color-text);
}

.footer-links-quick p:hover,
.footer-links-important p:hover {
  color: var(--color-bgLightRed);
  transform: scale(1.01);
  cursor: pointer;
}

.footer-socials {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.footer-credit {
  font-size: 11px;
}
@media screen and (max-width: 768px) {
  .footer-links-quick p,
  .footer-links-important p {
    font-size: 13px;
  }
  .footer-links-quick h4,
  .footer-links-important h4,
  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-links-quick h4,
  .footer-links-important h4 {
    width: 130px;
  }

  .footer-links-quick,
  .footer-links-important {
    justify-content: flex-start;
  }
}
